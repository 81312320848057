import TopMenu from "../components/navbar/TopMenu";
import React from "react";
import {BasicTabs} from "../components/tabs/BasicTabs";

const Admin = () => {
    return (
        <>
            <TopMenu/>
            <BasicTabs/>
        </>

    );
}

export default Admin;