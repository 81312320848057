export enum ApiEnum {
    AUTH = "/api/v1/auth/login",
    GET_PLAYERS = "/api/v1/players",
    GET_ALL_PLAYERS = "/api/v1/all_players",
    GET_PLAYER = "/api/v1/player",
    GET_PLAYER_SETTINGS = "/api/v1/player/settings",
    GET_LOCATIONS = "/api/v1/locations",
    GET_LOCATION = "/api/v1/location",
    UPSERT_LOCATION = "/api/v1/location/upsert",
    UPSERT_TOURNAMENT = "/api/v1/tournament/upsert",
    GET_TOURNAMENTS = "/api/v1/tournaments",
    GET_TOURNAMENT = "/api/v1/tournament",
    GET_ADMIN_CONFIG = "/api/v1/admin_config",
    GET_RANDOM_TITLE = "/api/v1/random_title",
    REGISTER_FOR = "/api/v1/tournament/register",
    GET_COMMON_STAT = "/api/v1/common_stat",
    GET_STAT = "/api/v1/stat",
}